import { ColumnState } from 'ag-grid-community';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Theme } from '@idearoom/ir-code/lib';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { Permissions } from '../constants/Permissions';
import { UserStatus, UserPreference } from '../constants/User';
import { Group } from './Group';
import { PreferencesFormFields } from '../constants/FormFields';
import { GridViewType } from '../constants/GridViewType';
import { MenuStatus } from '../constants/Viewer';

export interface User {
  readonly sub?: string;
  readonly userHash?: string;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly name: string;
  readonly phone: string;
  readonly dealers: string[];
  readonly permissions: Permissions[];
  readonly status?: UserStatus;
  readonly username: string;
  readonly lastLogin?: string;
}

export const unknownUser: User = {
  sub: '',
  userHash: '',
  username: '',
  email: '',
  firstName: '',
  lastName: '',
  name: '',
  phone: '',
  dealers: [],
  permissions: [Permissions.Sales],
  status: UserStatus.Unknown,
  lastLogin: 'N/A',
};

export interface CurrentUserData {
  readonly user: User;
  readonly group: Group;
}

export interface ClientDataPreferences {
  [table: string]: {
    [column: string]: ColumnState & { order: number };
  };
}

export interface PricingComponentPreferences {
  gridState?: GridInitialStatePremium;
}

export interface PricingBasePreferences {
  gridViewType: { [groupId: string]: { [clientId: string]: GridViewType } };
}

export interface ProfilePreferences {
  [PreferencesFormFields.Theme]?: Theme;
  [PreferencesFormFields.MiniMap]?: boolean;
  editorSize: { width: string; height: string };
  menuStatus?: MenuStatus;
}

export interface UserPreferences {
  [UserPreference.ClientDataPreferences]: ClientDataPreferences;
  [UserPreference.PricingBasePreferences]?: PricingBasePreferences;
  [UserPreference.PricingComponentPreferences]?: PricingComponentPreferences;
  [UserPreference.ProfilePreferences]?: ProfilePreferences;
}
