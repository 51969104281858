import { PriceCalculation, PriceColumn } from '@idearoom/types';
import { Dispatch } from 'react';
import { change } from 'redux-form';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { ClientDataType } from '../constants/ClientDataType';
import {
  ActionColumn,
  CLIENT_UPDATE_CATEGORY_MAPPING,
  componentColumnMap,
  DisplayColumns,
  ComponentFormData,
  HelperColumns,
  MiscPriceColumns,
  PRICING_TYPE_MAP,
  PricingCalculationColumns,
} from '../constants/PricingClientUpdate';
import { PricingComponentEditFields } from '../constants/FormFields';
import {
  ClientUpdateCategory,
  ClientUpdateCategoryKey,
  ComponentCategoryItem,
  ComponentCategoryItemWithConditions,
  ConditionalPrice,
  SizeBasedPricingSheet,
} from '../types/PricingClientUpdate';
import { TableData } from '../types/DataGrid';
import { addDispatchCommandToUndo } from './undoManagerUtils';
import {
  setPricingComponentDataBranch,
  setPricingSizeBasedDataBranch,
  updatePricingComponentRows,
} from '../ducks/pricingSlice';
import { arePriceValuesDifferent, getValidatedNewValue, formatPrice, isDecimalPrice } from './pricingUtils';
import { UpdateClientDataRow } from '../ducks/clientDataSlice';
import { mapClientAndDataTypeAndTableToUndoStackId } from './clientIdUtils';
import { AppDispatch } from '../hooks';
import { compoundCaseToTitleCase, fuzzyMatchIncludes, kebabCaseToTitleCase, pluralizeString } from './stringUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { i18n } from '../i18n';
import { Forms } from '../constants/Forms';
import { Region } from '../types/Region';
import { PricingTab } from '../constants/Pricing';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ComponentCategoryKey, SizeBasedCategoryKey } from '../constants/ClientUpdateCategoryKey';
import { getPricingSheetTable } from './pricingSheetUtils';
import { OPTION_CONDITION_TABLE } from '../constants/ClientData';

/**
 * Returns whether the field is a pricing field including upgrade price.
 * @param field field to evaluate
 * @returns whether the field is a pricing field
 */
export const isPricingField = (field: string) =>
  [PriceColumn, MiscPriceColumns].some((e) => Object.values(e).includes(field));

/**
 * Returns whether the field is a regional pricing field.
 * @param field field to evaluate
 * @returns whether the field is a regional pricing field
 */
export const isRegionalPricingField = (field: string) =>
  isPricingField(field) && field.toLowerCase().includes('region');

/**
 * Determines whether the condition should be used in place of the default price.
 * Right now just confirms that the condition has a price.
 *
 * @param condition condition to evaluate
 * @returns
 */
export const isMatchingPriceCondition = (condition: ConditionalPrice, priceColumn: string = PriceColumn.price) =>
  (condition as any)[priceColumn] || condition[PriceColumn.price];

/**
 * Return the item or condition data that should be used for a given column.
 *
 * @param item component category item with conditions
 * @param column column name
 * @returns item or condition data
 */
export const getMatchingItemOrConditionData = (item: ComponentCategoryItemWithConditions, column: string) => {
  const { item: componentData, conditions } = item;

  if (!(Object.values(PriceColumn) as string[]).includes(column)) return componentData;

  const pricingCondition = conditions.find((c) => isMatchingPriceCondition(c, column));
  return pricingCondition || componentData;
};

/**
 * Returns all row data for the item that matches the price of the primary matching item/condition data for the item
 *
 * @param item component category item with conditions
 * @param column column name
 * @returns all matching item or condition data
 */
export const getAllMatchingItemOrConditionData = (item: ComponentCategoryItemWithConditions, column: string) => {
  const matchingItemOrConditionData = getMatchingItemOrConditionData(item, column);
  const { [column]: valueToMatch } = matchingItemOrConditionData as unknown as Record<string, string>;

  const { item: componentData, conditions } = item as unknown as {
    item: Record<string, string>;
    conditions: Record<string, string>[];
  };
  return [
    ...(componentData[column] === valueToMatch ? [componentData] : []),
    ...conditions.filter((c) => c[column] === valueToMatch),
  ];
};

/**
 * Determines whether the item/condition data should be editable. This is a safeguard to prevent editing items that are using option conditions for pricing
 * and have multiple conditions with different prices. In this case, editing isn't allowed and "Varies" is displayed in the UI.
 *
 * @param item component category item with conditions
 * @param column column name
 * @returns whether the item or condition data should be editable
 */
export const allowEditingItemOrConditionData = (item: ComponentCategoryItemWithConditions, column: string) => {
  const allMatchingItemOrConditionData = getAllMatchingItemOrConditionData(item, column);
  const matchingConditionData = allMatchingItemOrConditionData.filter(
    (rowData) => rowData[ClientDataFixedColumns.RowId] !== item.item[ClientDataFixedColumns.RowId],
  );

  // Allow editing the component data (not using conditions)
  if (allMatchingItemOrConditionData.length === 1 && matchingConditionData.length === 0) return true;

  // Allow editing the component data only if all conditions match the price
  return matchingConditionData.length === item.conditions.length;
};

/**
 * Returns a parsed and formatted price value for a given field and row/form data.
 *
 * @param column column name
 * @param rowData data for the component row
 * @returns parsed and formatted price value
 */
export const parsePriceValue = (column: string, rowData: any): number | string | null => {
  const value = `${rowData?.[column]}` || '';
  const valueWithoutCurrency = value.replace(/[$€£¥₣,]/g, '');

  if (rowData?.priceExpression) return i18n.t(I18nKeys.PricingComponentPriceExpressionPrice) as string;

  const valueAsNumber = parseFloat(valueWithoutCurrency || '0');
  if (Number.isNaN(valueAsNumber)) return null;
  return valueAsNumber.toFixed(2);
};

/**
 * Returns whether the given region column is using the default price column's value.
 *
 * @param column column name
 * @param rowData data for the component row
 * @returns whether the region column is using the default price column's value
 */
export const usingDefaultRegionPrice = (column: string, componentCategoryItem: ComponentCategoryItemWithConditions) => {
  const rowData = getMatchingItemOrConditionData(
    componentCategoryItem,
    column as keyof ComponentCategoryItem,
  ) as unknown as Record<string, string | number | null | undefined>;
  // If the value is null or undefined, it falls back to the default price
  if (rowData[column] === undefined || rowData[column] === null) return true;
  return (
    parsePriceValue(column as keyof ComponentCategoryItem, rowData as any) ===
    parsePriceValue(PriceColumn.price, rowData as any)
  );
};

/**
 * Returns whether any of the component category item's region prices differ from the default price.
 *
 * @param rowData data for the component row
 * @returns whether any region prices differ from the default price
 */
export const pricingVariesByRegion = (componentCategoryItem: ComponentCategoryItemWithConditions) =>
  Object.keys(componentCategoryItem.item).some(
    (col) => isRegionalPricingField(col) && !usingDefaultRegionPrice(col, componentCategoryItem),
  );

export const getComponentFieldLabel = (
  field: keyof ComponentFormData | PriceColumn | ActionColumn,
  regions: Region[],
) => {
  if (field === DisplayColumns.PriceExpression) return '';

  if (isRegionalPricingField(field)) {
    const regionLabel = regions.find(({ priceColumn }) => priceColumn === (field as string))?.label;
    if (regionLabel) return regionLabel;
  }

  return compoundCaseToTitleCase(field);
};

/**
 * Responds to an edit of a component category item's price field and updates any region prices that are using the default price.
 *
 * @param event event that triggered the change
 * @param currentValues current form data
 * @param dispatch
 * @returns
 */
export const onComponentFieldChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  currentValues: ComponentFormData,
  dispatch: Dispatch<any>,
) => {
  const { name: field, value } = event.target;

  if (field !== PriceColumn.price) return;

  Object.keys(currentValues).forEach((f) => {
    if (
      !isRegionalPricingField(f) ||
      parsePriceValue(f, currentValues) !== parsePriceValue(PriceColumn.price, currentValues)
    )
      return;
    dispatch(change(Forms.PricingComponentEdit, f, value));
  });
};

/**
 * Finds a price for the given component and regional price column. Uses regional price if available, otherwise uses default price.
 *
 * @param componentId row ID of the component
 * @param priceColumn regional price column
 * @param componentCategoryItemsWithConditions all component category items with conditions
 * @returns matched price for the component
 */
export const getMatchedPriceFromConditions = (
  priceColumn: string,
  componentCategoryItemWithConditions: ComponentCategoryItemWithConditions,
) => {
  const { [priceColumn]: regionPrice, [PriceColumn.price]: defaultPrice } = getMatchingItemOrConditionData(
    componentCategoryItemWithConditions,
    priceColumn as keyof ComponentCategoryItem,
  ) as any;

  if (!(Object.values(PriceColumn) as string[]).includes(priceColumn)) return regionPrice;

  return regionPrice || defaultPrice;
};

/**
 * Gets the starting value for a component pricing field.
 *
 * @param column column name
 * @param rowData data for the component row
 * @returns initial value for the component pricing field
 */
export const getDefaultParsedComponentPricingColumnValue = (
  column: keyof ComponentCategoryItem,
  categoryKey: ComponentCategoryKey | undefined,
  componentCategoryItemWithConditions: ComponentCategoryItemWithConditions,
  currency?: string,
  useDecimalFormat = false,
) => {
  const { t } = i18n;
  const { item } = componentCategoryItemWithConditions;
  const rowData = getMatchingItemOrConditionData(componentCategoryItemWithConditions, column) as any;
  const value = rowData?.[column];

  if (Object.values(PricingCalculationColumns).some((v) => v === column)) {
    return (
      (item?.priceExpression && t(I18nKeys.PricingComponentPriceExpressionCalculation)) ||
      value ||
      (categoryKey && CLIENT_UPDATE_CATEGORY_MAPPING[categoryKey]?.defaultPricingType) ||
      PriceCalculation.Amount
    );
  }

  if (isPricingField(column)) {
    if (!allowEditingItemOrConditionData(componentCategoryItemWithConditions, column))
      return t(I18nKeys.PricingComponentPriceVaries);

    const effectivePrice = getMatchedPriceFromConditions(column, componentCategoryItemWithConditions);

    if (([MiscPriceColumns.UpgradePrice] as string[]).includes(column) && !effectivePrice && effectivePrice !== 0)
      return null;

    return formatPrice(effectivePrice, currency, useDecimalFormat ? 2 : 0, useDecimalFormat ? 2 : 0);
  }
  return value;
};

/**
 * Finds all available component pricing form fields for the given component ID's table columns
 * and includes initial values if data exists.
 * Pricing calculation fields default to 'Amount' if no value exists.
 *
 * @param componentRowId component row ID
 * @param componentCategoryItems all component data for the category
 * @returns component pricing form fields and initial values
 */
export const getComponentPricingFormInitialValues = (
  componentRowId: string | number,
  categoryKey: ComponentCategoryKey,
  componentCategoryItems: ComponentCategoryItemWithConditions[],
  currency?: string,
): Record<string, string | number | null | undefined> | undefined => {
  const componentCategoryItemWithConditions = componentCategoryItems.find(({ item }) => item.rowId === componentRowId);

  if (!componentCategoryItemWithConditions) {
    return undefined;
  }

  const { item: componentData } = componentCategoryItemWithConditions;
  const useDecimalFormat = Object.keys(componentData).some(
    (col) =>
      isPricingField(col) && isDecimalPrice(getMatchedPriceFromConditions(col, componentCategoryItemWithConditions)),
  );
  const columns = [
    ...Object.keys(componentData).filter((c) =>
      [DisplayColumns, PriceColumn, MiscPriceColumns].some((e) => Object.values(e).some((v) => v === c)),
    ),
    // Always include price calculation column
    PricingCalculationColumns.PriceCalculation,
  ];
  return Object.fromEntries(
    [
      [PricingComponentEditFields.Component, componentRowId],
      [PricingComponentEditFields.Table, componentData.table],
      [HelperColumns.Key, componentData.key],
      ...columns
        .map((c) => [
          c,
          getDefaultParsedComponentPricingColumnValue(
            c as keyof ComponentCategoryItem,
            categoryKey,
            componentCategoryItemWithConditions,
            currency,
            useDecimalFormat,
          ),
        ])
        // Do not display price expression column if a value does not exist
        .filter(
          ([c, v]) =>
            !([DisplayColumns.PriceExpression, MiscPriceColumns.UpgradePrice] as string[]).includes(c as string) || !!v,
        ),
      [HelperColumns.VariesByRegion, pricingVariesByRegion(componentCategoryItemWithConditions)],
    ].sort(([a]: any, [b]: any) => (componentColumnMap[a]?.order || 0) - (componentColumnMap[b]?.order || 0)),
  );
};

export const getChangedValues = (
  categoryKey: ComponentCategoryKey,
  updates: { data: TableData; column: string; oldValue: any; newValue: any }[],
) => {
  const newValues: { data: TableData; column: string; oldValue: any; newValue: any }[] = [];

  updates.forEach(({ data, column, oldValue, newValue }) => {
    let newVal: string | null = `${newValue}`.trim();
    let oldVal: string = `${oldValue}`.trim();

    let valueChanged = oldVal !== newVal;
    if (isPricingField(column)) {
      newVal = newValue === null ? newValue : getValidatedNewValue(newValue, oldVal);
      oldVal = getValidatedNewValue(oldVal, oldVal);
      valueChanged = arePriceValuesDifferent(oldVal, newVal);
    } else if (Object.values(PricingCalculationColumns).some((v) => v === column)) {
      valueChanged =
        valueChanged &&
        (!!oldValue ||
          newVal !== (CLIENT_UPDATE_CATEGORY_MAPPING[categoryKey]?.defaultPricingType || PriceCalculation.Amount));
    }

    if (valueChanged) {
      newValues.push({ data: { ...data, [column]: newVal }, column, oldValue, newValue });
    }
  });

  return newValues;
};

const updateValues = (
  clientDataTableId: string,
  categoryKey: ComponentCategoryKey,
  updates: { data: TableData; column: string; oldValue: any; newValue: any }[],
  dispatch: AppDispatch,
) => {
  const [, , table] = clientDataTableId.split(':');

  const newValues = getChangedValues(categoryKey, updates);

  const newRows: (UpdateClientDataRow & { table: string })[] = [];
  const oldRows: (UpdateClientDataRow & { table: string })[] = [];

  newValues.forEach(({ data, column, oldValue, newValue }) => {
    oldRows.push({ table, rowData: data, column, value: oldValue, formula: undefined });
    newRows.push({ table, rowData: data, column, value: newValue, formula: undefined });
  });

  if (newRows.length > 0) {
    addDispatchCommandToUndo(
      dispatch,
      [updatePricingComponentRows(oldRows)],
      [updatePricingComponentRows(newRows)],
      clientDataTableId,
      true,
    );
  }
};

/**
 * Used to filter out form data fields and values that should not be edited.
 *
 * @param formData component edit form data
 * @param editedComponentCategoryItem edited component category item with conditions
 * @returns filtered form data
 */
export const filterComponentEditFormData = (
  formData: ComponentFormData,
  editedComponentCategoryItem: ComponentCategoryItemWithConditions,
) =>
  Object.entries(formData).filter(([c, value]) => {
    let newValue = value;
    let oldValue = (
      getMatchingItemOrConditionData(editedComponentCategoryItem, c as keyof ComponentCategoryItem) as any
    )[c];
    const newDefaultPrice = parsePriceValue(PriceColumn.price, formData);
    const oldDefaultPrice = parsePriceValue(
      PriceColumn.price,
      getMatchingItemOrConditionData(editedComponentCategoryItem, PriceColumn.price),
    );

    if (isPricingField(c)) {
      [newValue, oldValue] = [value, oldValue].map((v) => parsePriceValue(c, { [c]: v }));
    }

    // Only allow edits to editable columns
    if (![PriceColumn, MiscPriceColumns, [DisplayColumns.Label]].flatMap((e) => Object.values(e)).includes(c))
      return false;

    // Ignore changes to prices if a price expression exists
    if (isPricingField(c) && formData[DisplayColumns.PriceExpression]) return false;

    // Previously using default price and no changes or prices do not vary by region
    if (
      isRegionalPricingField(c) &&
      ((usingDefaultRegionPrice(c, editedComponentCategoryItem) && newValue === oldDefaultPrice) ||
        !formData[HelperColumns.VariesByRegion])
    ) {
      if (oldValue == null) return false;
      if (newValue !== newDefaultPrice) return true;
    }

    if (isPricingField(c) && value !== null && !newValue && newValue !== 0) {
      return false;
    }

    // Filter out fields that haven't changed
    return newValue !== oldValue;
  });

/**
 * Gets a list of updates to be made based on the given form data and edited component category item.
 * Filters out fields that should not be edited via filterComponentEditFormData. Adds in additional updates
 * for any "update groups" that are defined for the category. Also adds in updates for other row data for the item
 * that matches the price of the primary matching item/condition data for the item.
 *
 * @param clientId client ID
 * @param categoryKey selected category key
 * @param formData component edit form data
 * @param editedComponentCategoryItem edited component category item with conditions
 * @param allComponentCategoryItems all component category items with conditions
 * @returns list of updates to be made
 */
export const getComponentEditUpdates = (
  clientId: string,
  categoryKey: ComponentCategoryKey,
  formData: ComponentFormData,
  editedComponentCategoryItem: ComponentCategoryItemWithConditions,
  allComponentCategoryItems: ComponentCategoryItemWithConditions[],
): { tableId: string; data: TableData; column: string; oldValue: any; newValue: any }[] => {
  const { item: componentData } = editedComponentCategoryItem;
  const updateGroups = CLIENT_UPDATE_CATEGORY_MAPPING[categoryKey]?.updateGroups || [];

  const [componentTableId, optionConditionTableId] = [componentData.table, 'optionCondition'].map((t) =>
    mapClientAndDataTypeAndTableToUndoStackId(clientId, ClientDataType.Supplier, t),
  );

  const filteredUpdates = filterComponentEditFormData(formData, editedComponentCategoryItem);

  const updates = filteredUpdates.reduce((allUpdatesForAllItems, [c, newValue]) => {
    // Is there an update group for this field?
    const updateGroupForField = updateGroups.find(({ field }) => field === c);

    // Other items that are in a "group" with this item because of shared fields
    const otherItemsInGroup = allComponentCategoryItems.filter(
      (otherItem) =>
        otherItem.item[ClientDataFixedColumns.RowId] !== componentData[ClientDataFixedColumns.RowId] &&
        updateGroupForField?.duplicates?.every((duplicatedColumn) => {
          const [editedItemValue, otherItemValue] = [editedComponentCategoryItem, otherItem].map((item) => {
            const data = getMatchingItemOrConditionData(item, duplicatedColumn) as unknown as Record<string, string>;
            return data?.[duplicatedColumn];
          });
          return editedItemValue === otherItemValue;
        }),
    );

    const updatesForThisField = [editedComponentCategoryItem, ...otherItemsInGroup].reduce(
      (allUpdatesForThisField, item) => {
        let value = newValue;

        // For this item and field, if it is a price field, update all conditions and the item itself that share the same price
        const allUpdatesForThisItemAndField = getAllMatchingItemOrConditionData(item, c).map(
          (rowData: Record<string, string>) => {
            const oldValue = rowData[c];
            const newDefaultPrice = parsePriceValue(PriceColumn.price, formData);
            const oldDefaultPrice = parsePriceValue(
              PriceColumn.price,
              getMatchingItemOrConditionData(editedComponentCategoryItem, PriceColumn.price),
            );

            // Format the value if it's a number
            if (isPricingField(c)) {
              value = parsePriceValue(c, { [c]: value });
            }

            if (
              isRegionalPricingField(c) &&
              ((usingDefaultRegionPrice(c, editedComponentCategoryItem) && value === oldDefaultPrice) ||
                !formData[HelperColumns.VariesByRegion])
            ) {
              value = newDefaultPrice;
            }

            // Format the value if it's a number
            if (isPricingField(c)) {
              value = parsePriceValue(c, { [c]: value });
            }

            return {
              tableId:
                rowData[ClientDataFixedColumns.RowId] === item.item[ClientDataFixedColumns.RowId]
                  ? componentTableId
                  : optionConditionTableId,
              data: { [ClientDataFixedColumns.RowId]: rowData.rowId },
              column: c,
              oldValue,
              newValue: value,
            };
          },
        );

        return [...allUpdatesForThisField, ...allUpdatesForThisItemAndField];
      },
      [] as { tableId: string; data: TableData; column: string; oldValue: any; newValue: any }[],
    );

    return [...allUpdatesForAllItems, ...updatesForThisField];
  }, [] as { tableId: string; data: TableData; column: string; oldValue: any; newValue: any }[]);

  return updates;
};

/**
 * Responds to a component edit form submission and updates the component category items with the new values.
 *
 * @param clientId client ID
 * @param categoryKey selected category key
 * @param formData form data
 * @param componentCategoryItems component category items
 * @param dispatch Redux dispatch
 */
export const onComponentEditSubmit = (
  clientId: string,
  categoryKey: ComponentCategoryKey | undefined,
  formData: ComponentFormData,
  componentCategoryItems: ComponentCategoryItemWithConditions[],
  dispatch: AppDispatch,
) => {
  const componentId = formData[PricingComponentEditFields.Component];
  const editedItem = componentCategoryItems.find(
    ({ item: { [ClientDataFixedColumns.RowId]: rowId } }) => rowId === componentId,
  );

  if (!editedItem) return;

  if (!categoryKey) {
    // eslint-disable-next-line no-console
    console.error('categoryKey is undefined');
    return;
  }

  const [componentTableId, optionConditionTableId] = [editedItem.item.table, 'optionCondition'].map((t) =>
    mapClientAndDataTypeAndTableToUndoStackId(clientId, ClientDataType.Supplier, t),
  );

  const updates = getComponentEditUpdates(clientId, categoryKey, formData, editedItem, componentCategoryItems);

  [componentTableId, optionConditionTableId].forEach((tableId) => {
    // Filter to only the updates for this table
    const tableUpdates = updates
      .filter(({ tableId: t }) => t === tableId)
      .map((update) => ({ ...update, tableId: undefined }));
    if (tableUpdates.length > 0) {
      updateValues(tableId, categoryKey, tableUpdates, dispatch);
    }
  });
};

/**
 * Transforms a list of component category items with conditions into a list of component category items.
 *
 * @param componentCategoryItemsWithConditions list of component category items with conditions
 * @returns list of component category items
 */
export const getComponentCategoryItems = (
  componentCategoryItemsWithConditions: ComponentCategoryItemWithConditions[] = [],
) => componentCategoryItemsWithConditions.map(({ item }) => item);

/**
 * Filters component category items or size based pricing sheets based on a search value.
 *
 * @param searchValue search value
 * @param componentCategoryItems component category items or size based pricing sheets
 * @returns filtered component category items or size based pricing sheets
 */
export const filterClientUpdateItems = (
  searchValue: string,
  componentCategoryItems: ComponentCategoryItem[] | SizeBasedPricingSheet[] = [],
) =>
  (componentCategoryItems as any[]).filter(
    (item) =>
      !searchValue ||
      Object.entries(item)
        .filter(([column]) =>
          (
            [PricingCalculationColumns, DisplayColumns, PriceColumn, MiscPriceColumns].flatMap((e) =>
              Object.values(e),
            ) as string[]
          ).includes(column),
        )
        .some(([_, val]) => fuzzyMatchIncludes(`${val}`, searchValue)),
  ) as ComponentCategoryItem[];

/**
 * Filters client update categories based on a search value.
 *
 * @param searchValue search value
 * @param categories client update categories
 * @returns filtered client update categories
 */
export const filterClientUpdateCategories = (searchValue: string, categories: ClientUpdateCategory[] = []) =>
  categories.filter(
    ({ key }) =>
      !searchValue ||
      [CLIENT_UPDATE_CATEGORY_MAPPING[key]?.label, key].some((val) => fuzzyMatchIncludes(`${val}`, searchValue)),
  );

/**
 * Gets the search count text for the client update search.
 *
 * @param searchValue search value
 * @param pricingTab selected pricing tab
 * @param categoryKey selected category key
 * @param filteredItems filtered display items
 * @param items non-filtered display items
 * @param categories client update categories
 * @returns search count text
 */
export const getClientUpdateSearchCountText = (
  searchValue: string,
  pricingTab: string,
  categoryKey?: ClientUpdateCategoryKey,
  filteredItems?: ClientUpdateCategory[] | ComponentCategoryItem[] | SizeBasedPricingSheet[],
  items?: ClientUpdateCategory[] | ComponentCategoryItem[] | SizeBasedPricingSheet[],
  categories?: ClientUpdateCategory[],
) => {
  const groupItemsName = 'category';
  const itemName = pricingTab === PricingTab.SizeBased ? 'price sheet' : 'component';

  const { total = 0, label } = categoryKey
    ? { total: items?.length, label: itemName }
    : { total: categories?.length, label: groupItemsName };
  const pluralizedLabel = total > 1 ? pluralizeString(label) : label;

  if (!searchValue) return `${total} ${pluralizedLabel}`;
  return `${filteredItems?.length || 0} of ${total} ${pluralizedLabel}`;
};

/**
 * Gets the label for a pricing calculation. Mainly used by "Amount" to display "Each" in the UI.
 *
 * @param type pricing calculation type
 * @returns label for the pricing calculation
 */
export const getPricingTypeLabel = (type: PriceCalculation) => {
  const { t } = i18n;
  const uniqueKey = PRICING_TYPE_MAP[type];
  return uniqueKey ? t(uniqueKey) : kebabCaseToTitleCase(type);
};

/**
 * Gets which pricing data branch to use based on the selected pricing tab.
 *
 * @param pricingTab selected pricing tab
 * @returns client data branch
 */
export const getClientUpdateBranch = (pricingTab: string | undefined) =>
  pricingTab === PricingTab.Component ? ClientDataBranch.ClientUpdate : ClientDataBranch.PricingSizeBased;

/**
 * Gets function to set pricing data branch based on the selected pricing tab.
 *
 * @param pricingTab selected pricing tab
 * @returns function to set pricing data branch
 */
export const getSetClientUpdateBranch = (pricingTab: string | undefined) =>
  pricingTab === PricingTab.Component ? setPricingComponentDataBranch : setPricingSizeBasedDataBranch;

export const getClientUpdatePricingTables = (
  clientId: string,
  pricingTab: string | undefined,
  categoryKey: ClientUpdateCategoryKey | undefined,
  componentCategoryItems: ComponentCategoryItem[] = [],
) => {
  const uniqueTables = new Set<string>();
  switch (pricingTab) {
    case PricingTab.Component:
      componentCategoryItems.forEach(({ table }) => uniqueTables.add(table));
      break;
    case PricingTab.SizeBased:
      uniqueTables.add(getPricingSheetTable(clientId, pricingTab, categoryKey as SizeBasedCategoryKey) || '');
      break;
    default:
      break;
  }
  return [...Array.from(uniqueTables).filter(Boolean), OPTION_CONDITION_TABLE];
};

/**
 * Determines whether vary by region UI elements should be displayed.
 * For instance, extra pricing fields in the component pricing edit form or the "Vary By Region" checkbox in the items table.
 *
 * @param regions - regions for the vendor
 * @param tableColumns - columns that are available in the current table
 * @returns whether vary by region UI elements should be displayed
 */
export const displayVaryByRegion = (regions: Region[], tableColumns: string[]) =>
  !!regions.length &&
  tableColumns.some((col) => isRegionalPricingField(col) && regions.some(({ priceColumn }) => priceColumn === col));

/**
 * Get a label describing the style keys a component category item belongs to.
 * Abbreviated version is used in the component category item table and full version is used in the component category item edit form.
 *
 * @param rowId component category item row ID
 * @param styles all visible styles
 * @param abbreviated whether to use the abbreviated version
 * @param categoryKey selected category key
 * @param componentCategoryItems all component category items
 * @returns label describing the style keys
 */
export const getStyleKeyLabel = (
  rowId: string,
  styles: { key: string; label: string }[],
  abbreviated: boolean,
  categoryKey: ComponentCategoryKey | undefined,
  componentCategoryItems: ComponentCategoryItemWithConditions[],
) => {
  const { item } =
    componentCategoryItems.find(({ item: { [ClientDataFixedColumns.RowId]: id } }) => id === rowId) || {};

  if (!item || !categoryKey) return '';

  const keys = Array.from(
    new Set(
      componentCategoryItems
        .filter(({ item: i }) => CLIENT_UPDATE_CATEGORY_MAPPING[categoryKey]?.rowGroups?.every((c) => i[c] === item[c]))
        .flatMap(({ item: { styleKey } }) => styleKey?.split(',') || []),
    ),
  );

  let allStylesLabel = '(any)';
  let filteredStylesLabel = keys.map((key) => styles.find(({ key: k }) => k === key)?.label).join(', ');

  if (!abbreviated) {
    allStylesLabel = `Any style of building`;
    filteredStylesLabel = `${keys.length} style${keys.length > 1 ? 's' : ''}: ${filteredStylesLabel}`;
  }

  return styles.every(({ key }) => keys.includes(key)) || !keys.length ? allStylesLabel : filteredStylesLabel;
};

/**
 * Gets a list of columns to display in the component category item table
 *
 * @param componentCategoryItems all component category items
 * @param clientTableColumns columns available in relevant tables
 * @param regions regions for the vendor
 * @returns list of columns to display in the component category item table
 */
export const getComponentCategoryColumns = (
  componentCategoryItems: ComponentCategoryItemWithConditions[],
  clientTableColumns: { [table: string]: string[] },
  regions: Region[] = [],
) => [
  ...Array.from(
    new Set(
      componentCategoryItems
        .flatMap(({ item }) => Object.entries(item))
        .filter(([key, value]) => {
          // Only display upgrade price if a value exists, otherwise hide it
          if (MiscPriceColumns.UpgradePrice === key && (!!value || value === 0)) {
            return true;
          }
          if (DisplayColumns.Label === key) {
            return true;
          }
          return false;
        }, [] as string[])
        .map(([key]) => key),
    ),
  ),
  // Always include price calculation column
  PricingCalculationColumns.PriceCalculation,
  ...(componentCategoryItems.some(({ item: { table } }) =>
    displayVaryByRegion(regions || [], clientTableColumns[table]),
  )
    ? [HelperColumns.VariesByRegion]
    : []),
  ...(componentCategoryItems.some(({ item: { table } }) => clientTableColumns[table].includes(DisplayColumns.StyleKey))
    ? [DisplayColumns.StyleKey]
    : []),
  ...(regions || []).map(({ priceColumn: regionColumn }) => regionColumn),
  ActionColumn.Edit,
];

/**
 * Formats a column value of a component category item for display in the table view.
 *
 * @param property column name
 * @param row component category item
 * @param categoryKey selected category key
 * @param componentCategoryItems all component category items
 * @param styles all visible styles
 * @param currency vendor currency
 * @returns formatted column value
 */
export const formatComponentCategoryValue = (
  property: string,
  row: any,
  categoryKey: ComponentCategoryKey | undefined,
  componentCategoryItems: ComponentCategoryItemWithConditions[],
  styles: { key: string; label: string }[],
  currency = 'USD',
): string => {
  if (property === DisplayColumns.StyleKey)
    return getStyleKeyLabel(row[ClientDataFixedColumns.RowId], styles, true, categoryKey, componentCategoryItems);
  if (property === PricingCalculationColumns.PriceCalculation) return getPricingTypeLabel(row[property]).toLowerCase();
  // Don't display 0 for upgrade price if it's not set
  if (property === MiscPriceColumns.UpgradePrice && !row[property] && row[property] !== 0) {
    return '';
  }
  if (isPricingField(property)) {
    return formatPrice(row[property], currency, 0);
  }
  return row[property];
};

/**
 * Groups component category items by unique row groups if they exist for the category.
 *
 * @param categoryKey selected category key
 * @param componentCategoryItems component category items
 * @returns grouped component category items
 */
export const groupComponentCategoryItems = (
  categoryKey: ClientUpdateCategoryKey | undefined,
  componentCategoryItems: ComponentCategoryItemWithConditions[],
) =>
  componentCategoryItems.filter(
    ({ item: row }, i, arr) =>
      !categoryKey ||
      !arr
        .slice(0, i)
        .some(({ item: r }) => CLIENT_UPDATE_CATEGORY_MAPPING[categoryKey]?.rowGroups?.every((c) => r[c] === row[c])),
  );

export const getComponentCategoryItemRows = (
  categoryKey: ComponentCategoryKey | undefined,
  componentCategoryItemsWithConditions: ComponentCategoryItemWithConditions[],
  existingRows: any[],
  currency: string | undefined,
) => {
  const groupedRows = groupComponentCategoryItems(categoryKey, componentCategoryItemsWithConditions);
  const useDecimalFormat = groupedRows.some((itemWithConditions) =>
    Object.keys(itemWithConditions.item).some(
      (col) => isPricingField(col) && isDecimalPrice(getMatchedPriceFromConditions(col, itemWithConditions)),
    ),
  );
  return groupedRows
    .map((itemWithConditions) => {
      const { item: { [ClientDataFixedColumns.RowId]: rowId } = {} } = itemWithConditions;
      const existingRow = existingRows.find((r) => r[ClientDataFixedColumns.RowId] === rowId);

      const updatedRow = Object.fromEntries(
        Object.keys(itemWithConditions.item).map((column) => [
          column,
          getDefaultParsedComponentPricingColumnValue(
            column as keyof ComponentCategoryItem,
            categoryKey,
            itemWithConditions,
            currency,
            useDecimalFormat,
          ),
        ]),
      );
      return {
        ...(existingRow || {}),
        ...updatedRow,
        label: updatedRow.label,
        [PricingCalculationColumns.PriceCalculation]:
          (categoryKey && CLIENT_UPDATE_CATEGORY_MAPPING[categoryKey]?.defaultPricingType) || PriceCalculation.Amount,
        [HelperColumns.VariesByRegion]:
          existingRow?.[HelperColumns.VariesByRegion] === undefined
            ? pricingVariesByRegion(itemWithConditions)
            : existingRow?.[HelperColumns.VariesByRegion],
      };
    })
    .sort((a, b) => {
      // Sorting label by lowercase, spaces removed, alphabetically
      const labelA = a.label.toLowerCase().replace(/\s/g, '');
      const labelB = b.label.toLowerCase().replace(/\s/g, '');
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });
};
