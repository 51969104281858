/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { PriceColumn, PriceCalculation as PricingType } from '@idearoom/types';
import { ComponentCategoryKey, SizeBasedCategoryKey } from './ClientUpdateCategoryKey';
import DoorCategoryImage from '../images/door-category.png';
import GarageCategoryImage from '../images/garage-category.png';
import FrameoutCategoryImage from '../images/frameout-category.png';
import WindowCategoryImage from '../images/window-category.png';
import TransomCategoryImage from '../images/transom-category.png';
import DormerCategoryImage from '../images/dormer-category.png';
import CupolaCategoryImage from '../images/cupola-category.png';
import ShutterCategoryImage from '../images/shutter-category.png';
import FlowerBoxCategoryImage from '../images/flowerbox-category.png';
import VentCategoryImage from '../images/vent-category.png';
import RampCategoryImage from '../images/ramp-category.png';
import ShelfCategoryImage from '../images/shelf-category.png';
import WorkbenchCategoryImage from '../images/workbench-category.png';
import FlooringCategoryImage from '../images/flooring-category.png';
import SidingCategoryImage from '../images/siding-category.png';
import NestingBoxCategoryImage from '../images/nestingbox-category.png';
import { I18nKeys } from './I18nKeys';
import { ClientUpdateCategoryKey, ComponentCategoryItem } from '../types/PricingClientUpdate';
import { PricingComponentEditFields } from './FormFields';

export enum MiscPriceColumns {
  UpgradePrice = 'upgradePrice',
}

export enum PricingCalculationColumns {
  PriceCalculation = 'priceCalculation',
}

export enum DisplayColumns {
  Label = 'label',
  PriceExpression = 'priceExpression',
  StyleKey = 'styleKey',
}

export enum MiscProperties {
  LegLabel = 'legLabel',
}

export enum HelperColumns {
  Key = 'key',
  VariesByRegion = 'variesByRegion',
}

export enum ActionColumn {
  Edit = 'edit',
}

export enum PricingSheetHeaders {
  PricingSheetLabel = 'pricingSheetLabel',
  PriceRange = 'priceRange',
  HeightRange = 'heightRange',
}

export enum PricingSizeBasedAttributes {
  SingleSlope = 'singleSlope',
  Leg = 'leg',
  WidthTags = 'widthTags',
  CustomExpression = 'customExpression',
}

// Use to associate columns with order to be displayed in item table and price calculation fields
export const componentColumnMap: {
  [pricingField: string]: { calculation?: PricingCalculationColumns; order: number };
} = {
  [DisplayColumns.Label]: { order: 1 },
  [PriceColumn.price]: { calculation: PricingCalculationColumns.PriceCalculation, order: 2 },
  [PricingCalculationColumns.PriceCalculation]: { order: 3 },
  [MiscPriceColumns.UpgradePrice]: { order: 4 },
  [DisplayColumns.PriceExpression]: { order: 5 },
  [HelperColumns.VariesByRegion]: { order: 6 },
  [DisplayColumns.StyleKey]: { order: 7 },
  [PriceColumn.region1]: { order: 8 },
  [PriceColumn.region2]: { order: 9 },
  [PriceColumn.region3]: { order: 10 },
  [PriceColumn.region4]: { order: 11 },
  [PriceColumn.region5]: { order: 12 },
  [PriceColumn.region6]: { order: 13 },
  [PriceColumn.region7]: { order: 14 },
  [ActionColumn.Edit]: { order: 100 },
};

export interface ComponentFormData {
  [PricingComponentEditFields.Component]: string;
  [PricingComponentEditFields.Table]: string;
  [DisplayColumns.Label]: string;
  [PriceColumn.price]?: string;
  [PriceColumn.region1]?: string;
  [PriceColumn.region2]?: string;
  [PriceColumn.region3]?: string;
  [PriceColumn.region4]?: string;
  [PriceColumn.region5]?: string;
  [PriceColumn.region6]?: string;
  [PriceColumn.region7]?: string;
  [PricingCalculationColumns.PriceCalculation]?: string;
  [MiscPriceColumns.UpgradePrice]?: string;
  [DisplayColumns.PriceExpression]?: string;
  [DisplayColumns.StyleKey]?: string;
  [HelperColumns.VariesByRegion]: boolean;
  [HelperColumns.Key]: string;
}

export const CLIENT_UPDATE_CATEGORY_MAPPING: {
  [key in ClientUpdateCategoryKey]: {
    label: string;
    image?: string;
    defaultPricingType?: PricingType;
    // For all rows that duplicate all columns specified in "duplicates", update the "field"
    updateGroups?: {
      field: keyof ComponentCategoryItem;
      duplicates: (keyof ComponentCategoryItem)[];
    }[];
    // Rows with these columns will be grouped together visually
    // This doesn't impact the updates without "updateGroups"
    rowGroups?: (keyof ComponentCategoryItem)[];
  };
} = {
  [ComponentCategoryKey.Door]: {
    label: 'Doors',
    image: DoorCategoryImage,
  },
  [ComponentCategoryKey.GarageDoor]: {
    label: 'Garage Doors',
    image: GarageCategoryImage,
  },
  [ComponentCategoryKey.Frameout]: {
    label: 'Frameouts',
    image: FrameoutCategoryImage,
  },
  [ComponentCategoryKey.Window]: {
    label: 'Windows',
    image: WindowCategoryImage,
  },
  [ComponentCategoryKey.WindowTransom]: {
    label: 'Windows (Transom)',
    image: TransomCategoryImage,
  },
  [ComponentCategoryKey.Dormer]: {
    label: 'Dormers',
    image: DormerCategoryImage,
  },
  [ComponentCategoryKey.Cupola]: {
    label: 'Cupolas',
    image: CupolaCategoryImage,
  },
  [ComponentCategoryKey.Shutter]: {
    label: 'Shutters',
    image: ShutterCategoryImage,
  },
  [ComponentCategoryKey.FlowerBox]: {
    label: 'Flower Boxes',
    image: FlowerBoxCategoryImage,
  },
  [ComponentCategoryKey.NestingBox]: {
    label: 'Nesting Boxes',
    image: NestingBoxCategoryImage,
    updateGroups: [
      { field: DisplayColumns.Label, duplicates: [DisplayColumns.Label] },
      ...Object.values(PriceColumn).map((field) => ({
        field: field as keyof ComponentCategoryItem,
        duplicates: [DisplayColumns.Label, field] as (keyof ComponentCategoryItem)[],
      })),
    ],
    rowGroups: [DisplayColumns.Label, PriceColumn.price],
  },
  [ComponentCategoryKey.Vent]: {
    label: 'Vents',
    image: VentCategoryImage,
  },
  [ComponentCategoryKey.Ramp]: {
    label: 'Ramps',
    image: RampCategoryImage,
  },
  [ComponentCategoryKey.Shelf]: {
    label: 'Shelves',
    image: ShelfCategoryImage,
  },
  [ComponentCategoryKey.Workbench]: {
    label: 'Workbenches',
    image: WorkbenchCategoryImage,
  },
  [ComponentCategoryKey.Flooring]: {
    label: 'Flooring',
    image: FlooringCategoryImage,
    defaultPricingType: PricingType.PerSquareFoot,
    updateGroups: [
      { field: DisplayColumns.Label, duplicates: [DisplayColumns.Label] },
      ...Object.values(PriceColumn).map((field) => ({
        field: field as keyof ComponentCategoryItem,
        duplicates: [DisplayColumns.Label, field] as (keyof ComponentCategoryItem)[],
      })),
    ],
    rowGroups: [DisplayColumns.Label, PriceColumn.price],
  },
  [ComponentCategoryKey.Siding]: {
    label: 'Siding',
    image: SidingCategoryImage,
  },
  [SizeBasedCategoryKey.EndWalls]: {
    label: 'End Walls',
    image:
      'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/aaa79357-9476-4c1d-b9a8-6e881f2449d3/ddgdrbe-12584b9d-2ee7-4540-984a-fca6876b55f9.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2FhYTc5MzU3LTk0NzYtNGMxZC1iOWE4LTZlODgxZjI0NDlkM1wvZGRnZHJiZS0xMjU4NGI5ZC0yZWU3LTQ1NDAtOTg0YS1mY2E2ODc2YjU1ZjkucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.QN7lda26ydmuCTLJdK66DBbj8zZf6KW0rjr4Wqfrtog',
  },
  [SizeBasedCategoryKey.Insulation]: {
    label: 'Insulation',
    image:
      'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/aaa79357-9476-4c1d-b9a8-6e881f2449d3/ddgdrbe-12584b9d-2ee7-4540-984a-fca6876b55f9.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2FhYTc5MzU3LTk0NzYtNGMxZC1iOWE4LTZlODgxZjI0NDlkM1wvZGRnZHJiZS0xMjU4NGI5ZC0yZWU3LTQ1NDAtOTg0YS1mY2E2ODc2YjU1ZjkucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.QN7lda26ydmuCTLJdK66DBbj8zZf6KW0rjr4Wqfrtog',
  },
  [SizeBasedCategoryKey.LegHeight]: {
    label: 'Legs',
    image:
      'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/aaa79357-9476-4c1d-b9a8-6e881f2449d3/ddgdrbe-12584b9d-2ee7-4540-984a-fca6876b55f9.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2FhYTc5MzU3LTk0NzYtNGMxZC1iOWE4LTZlODgxZjI0NDlkM1wvZGRnZHJiZS0xMjU4NGI5ZC0yZWU3LTQ1NDAtOTg0YS1mY2E2ODc2YjU1ZjkucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.QN7lda26ydmuCTLJdK66DBbj8zZf6KW0rjr4Wqfrtog',
  },
  [SizeBasedCategoryKey.Panels]: {
    label: 'Panels',
    image:
      'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/aaa79357-9476-4c1d-b9a8-6e881f2449d3/ddgdrbe-12584b9d-2ee7-4540-984a-fca6876b55f9.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2FhYTc5MzU3LTk0NzYtNGMxZC1iOWE4LTZlODgxZjI0NDlkM1wvZGRnZHJiZS0xMjU4NGI5ZC0yZWU3LTQ1NDAtOTg0YS1mY2E2ODc2YjU1ZjkucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.QN7lda26ydmuCTLJdK66DBbj8zZf6KW0rjr4Wqfrtog',
  },
  [SizeBasedCategoryKey.SideWalls]: {
    label: 'Side Walls',
    image:
      'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/aaa79357-9476-4c1d-b9a8-6e881f2449d3/ddgdrbe-12584b9d-2ee7-4540-984a-fca6876b55f9.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2FhYTc5MzU3LTk0NzYtNGMxZC1iOWE4LTZlODgxZjI0NDlkM1wvZGRnZHJiZS0xMjU4NGI5ZC0yZWU3LTQ1NDAtOTg0YS1mY2E2ODc2YjU1ZjkucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.QN7lda26ydmuCTLJdK66DBbj8zZf6KW0rjr4Wqfrtog',
  },
  [SizeBasedCategoryKey.Roof]: {
    label: 'Roof',
    image:
      'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/aaa79357-9476-4c1d-b9a8-6e881f2449d3/ddgdrbe-12584b9d-2ee7-4540-984a-fca6876b55f9.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2FhYTc5MzU3LTk0NzYtNGMxZC1iOWE4LTZlODgxZjI0NDlkM1wvZGRnZHJiZS0xMjU4NGI5ZC0yZWU3LTQ1NDAtOTg0YS1mY2E2ODc2YjU1ZjkucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.QN7lda26ydmuCTLJdK66DBbj8zZf6KW0rjr4Wqfrtog',
  },
};

export const PRICING_TYPE_MAP: {
  [key in PricingType]?: I18nKeys;
} = {
  [PricingType.Amount]: I18nKeys.PricingComponentPricingTypeAmount,
};
