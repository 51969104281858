import {
  ColDef,
  GridApi,
  ProcessDataFromClipboardParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
  ColumnApi,
  CellClassParams,
  ColGroupDef,
  Column,
} from 'ag-grid-community';
import { v1 as uuidV1 } from 'uuid';
import { AnyAction, Dispatch } from 'redux';
import { TFunction } from 'i18next';
import { PriceColumn } from '@idearoom/types';
import { PricingSheetPrice } from '../types/PricingSheetPrice';
import { BaseTableData } from '../types/DataGrid';
import {
  formatPrice,
  getValidatedNewValue,
  arePriceValuesDifferent,
  getCurrencySymbol,
  isDecimalPrice,
} from './pricingUtils';
import {
  getCellRangeInfo,
  getPropertyFromCellMetadata,
  hasCellMetadataProperty,
  processUpdatedValues,
} from './clientDataUtils';
import { i18n } from '../i18n';
import { I18nKeys } from '../constants/I18nKeys';
import { addDispatchCommandToUndo } from './undoManagerUtils';
import {
  addPricingBaseRow,
  removePricingBaseRows,
  updatePricingSheetRows,
  updatePricingMetadata,
} from '../ducks/pricingSlice';
import { closeDialog, openDialog } from '../ducks/dialogSlice';
import { openNotificationDialog } from '../ducks/notification';
import { Dialogs } from '../constants/Dialogs';
import { LocalStorage } from '../constants/LocalStorage';
import { UpdateClientDataMetadata, UpdateClientDataRow } from '../ducks/clientDataSlice';
import { CellMetadata, ClipboardData, ColumnPinned } from '../types/ClientData';
import { CellMetadataProperty, COLUMN_DELIMITER, ROW_DELIMITER } from '../constants/ClientData';
import { PricingSheet } from '../types/PricingSheet';
import { GridViewType } from '../constants/GridViewType';
import { Region } from '../types/Region';
import {
  DefaultPricingColumnFieldNames,
  pricingBaseGridView,
  pricingBaseListView,
  PricingSheetDimension,
  pricingSizeBasedGridView,
  PricingTab,
} from '../constants/Pricing';
import { PricingSheetDimensions } from '../types/Pricing';
import { SelectAllCellsHeader } from '../components/SelectAllCellsHeader';
import {
  defaultColumn,
  rowSpanHeaderWidth,
  lengthColumnWidth,
  widthLengthColumnWidth,
  decimalPriceColumnWidth,
  priceColumnWidth,
} from '../constants/PricingBase';
import { getTextWidth } from './htmlUtil';
import { ClientDataTooltip } from '../components/ClientDataTooltip';
import { PricingGridTooltip } from '../components/PricingGridTooltip';
import { SizeBasedCategoryKey } from '../constants/ClientUpdateCategoryKey';
import { SHEDVIEW, getConfiguratorFromClientId } from './clientIdUtils';
import { getAttributeLabel, PricingSheetAttributeType } from '../constants/PricingSheetAttributeType';
import { openConfirmationDialog } from '../ducks/confirmation';
import { clearSelections } from './selectionUtils';

export interface PriceRow extends BaseTableData {
  [key: string]: any;
}

export const getAllUniqueDimensionValues = (dimension: PricingSheetDimension, prices: PricingSheetPrice[]) => {
  const allValues = prices.reduce((allValuesSeen: string[], price: PricingSheetPrice) => {
    const value = (price as Record<string, any>)[dimension];
    if (value && !allValuesSeen.includes(`${value}`)) {
      allValuesSeen.push(`${value}`);
    }
    return allValuesSeen;
  }, []);
  return allValues.sort((a, b) => Number.parseInt(a, 10) - Number.parseInt(b, 10));
};

export interface PricesPerRegionDiff {
  diff: number;
  missing: number;
}

export const getAvailablePricesPerRegionDiff = (
  configurator: string | undefined,
  availablePricePerRegion = new Map<string, PricesPerRegionDiff>(),
  regions: Region[],
  prices: PricingSheetPrice[],
): Map<string, { diff: number; missing: number }> => {
  const hasDefaultRegion = regions.find((r) => r.priceColumn === PriceColumn.price);
  if (!hasDefaultRegion) return new Map();

  regions.forEach((r) => {
    if (r.priceColumn !== PriceColumn.price) {
      let diff = 0;
      let missing = 0;
      prices.forEach((p) => {
        if ((p[r.priceColumn] || '') !== (p[PriceColumn.price] || '')) diff += 1;
        if (configurator === SHEDVIEW && (p[r.priceColumn] || '') === '' && (p[PriceColumn.price] || '') !== '')
          missing += 1;
      });
      availablePricePerRegion.set(r.rowId, { diff, missing });
    }
  });
  return availablePricePerRegion;
};

export const getSubtitleText = (
  availablePricePerRegion: Map<string, PricesPerRegionDiff>,
  sumAvailablePrices: number | undefined,
  region: Region,
  isHiddenRegion: boolean,
) => {
  if (isHiddenRegion) {
    return i18n.t(I18nKeys.PricingBaseAccordionHiddenRegion);
  }

  if (region.priceColumn === PriceColumn.price) {
    return i18n.t(I18nKeys.PricingBaseAccordionDefaultLabel);
  }
  const { diff = 0, missing = 0 } = availablePricePerRegion.get(region.rowId) || {};

  if (diff === 0) {
    return i18n.t(I18nKeys.PricingBaseAccordionNonePriceChanged, { total: sumAvailablePrices });
  }

  // TODO(Kevin): after deployed on production we should remove the i18n entry that does not use the missing interpolation
  let missingPriceText;
  if (missing > 0) {
    missingPriceText = i18n.t(I18nKeys.PricingBaseAccordionMissingPrice, {
      count: missing,
    });
  }

  if (diff === sumAvailablePrices) {
    return missingPriceText
      ? i18n.t(I18nKeys.PricingBaseAccordionAllPricesChangedMissing, {
          total: diff,
          missing: missingPriceText,
        })
      : i18n.t(I18nKeys.PricingBaseAccordionAllPricesChanged, {
          total: diff,
        });
  }

  return missingPriceText
    ? i18n.t(I18nKeys.PricingBaseAccordionCountPricesChangedMissing, {
        count: diff,
        missing: missingPriceText,
        total: sumAvailablePrices || 0,
      })
    : i18n.t(I18nKeys.PricingBaseAccordionCountPricesChanged, {
        count: diff,
        total: sumAvailablePrices || 0,
      });
};

const getDimensionHeaderLabel = (dimension: PricingSheetDimension, t: Function) => {
  let labelKey = '';
  switch (dimension) {
    case PricingSheetDimension.Length:
      labelKey = I18nKeys.PricingSheetLength;
      break;
    case PricingSheetDimension.Width:
      labelKey = I18nKeys.PricingSheetWidth;
      break;
    case PricingSheetDimension.Height:
      labelKey = I18nKeys.PricingSheetHeight;
      break;
    default:
      break;
  }
  return t(labelKey);
};

/**
 * Gets whether the region has any prices that are set
 *
 * @param prices
 * @returns
 */
export const regionHasPrices = (prices: PricingSheetPrice[] | undefined, region: Region) =>
  prices &&
  prices.some(
    (price) =>
      price[region.priceColumn] !== undefined && price[region.priceColumn] !== null && price[region.priceColumn] !== '',
  );

/**
 * Creates the row of prices from the pricing sheet. Sorts the rows based on available lengths.
 * @param prices
 * @param currency
 * @returns
 */
export const getRowData = (
  prices: PricingSheetPrice[],
  unit: string,
  gridViewType: GridViewType,
  pricingSheetDimensions: PricingSheetDimensions,
  priceColumn: PriceColumn = PriceColumn.price,
  regions: Region[] = [
    {
      priceColumn: PriceColumn.price,
      supplierKey: '',
      enabled: true,
      exclusionZone: false,
      label: '',
      rowId: '',
      regionKey: '',
    },
  ],
) => {
  const { x, y } = pricingSheetDimensions;
  const rowData: PriceRow[] = [];

  // return row data based on grid view type
  if (gridViewType === GridViewType.Grid) {
    const xAxis = x as PricingSheetDimension.Length | PricingSheetDimension.Width;
    const yAxisValues = getAllUniqueDimensionValues(y, prices);
    yAxisValues.forEach((value, i) => {
      let rowSpanLabel = '';
      if (i === 0) {
        rowSpanLabel = getDimensionHeaderLabel(y, i18n.t);
      }
      const pricesForThisDimension = prices.filter((price) => `${price[y]}` === value);

      const row: PriceRow = {
        rowId: `${i}`,
        priceY: value,
        [y]: `${value} ${unit}`,
        rowSpanLabel,
      };
      pricesForThisDimension.forEach((price) => {
        row[`${price[xAxis]}`] = {
          diff: price.diff,
          [priceColumn]: price[priceColumn] ? price[priceColumn] : '',
          clientDataRowId: price.rowId,
          hidden: price.hidden,
        };
      });
      rowData.push(row);
    });
  } else {
    [...prices]
      .sort((p1, p2) => {
        const [
          {
            [PricingSheetDimension.Width]: width1,
            [PricingSheetDimension.Length]: length1,
            [PricingSheetDimension.Height]: height1,
          },
          {
            [PricingSheetDimension.Width]: width2,
            [PricingSheetDimension.Length]: length2,
            [PricingSheetDimension.Height]: height2,
          },
        ] = [p1, p2];

        let sort = 0;
        [
          [width1, width2],
          [length1, length2],
          [height1, height2],
        ].forEach(([dim1, dim2]) => {
          if (dim1 !== undefined && dim2 !== undefined && sort === 0 && dim1 - dim2) {
            sort = dim1 - dim2;
          }
        });
        return sort;
      })
      .forEach((price, index) => {
        let rowSpanLabel = '';
        if (index === 0) {
          rowSpanLabel = `${i18n.t(I18nKeys.PricingSheetWidth)} x ${i18n.t(I18nKeys.PricingSheetLength)}`;
        }

        const row: PriceRow = {
          rowId: `${index}`,
          priceY: price.length,
          [y]: `${price.width}x${price.length} ${unit}`,
          rowSpanLabel,
        };

        for (let i = 0; i < regions.length; i += 1) {
          const region = regions[i];
          row[`${region.priceColumn}`] = {
            diff: price.diff,
            [region.priceColumn]: price[region.priceColumn] ? price[region.priceColumn] : '',
            clientDataRowId: price.rowId,
            hidden: price.hidden,
          };
        }
        rowData.push(row);
      });
  }

  return rowData;
};

const openCantEditDialog = (dispatch: Dispatch<any>) => {
  dispatch(openDialog({ dialog: Dialogs.PricingContactSupport }));
};

/**
 * AG Grid value setter for the pricing data. Handles the pricing value when the user edits a cell.
 *
 * @param clientDataTableId
 * @param params
 * @param cellMetadata
 * @param dispatch
 * @returns
 */
export const priceColumnValueSetter = (
  clientDataTableId: string,
  priceColumn: string,
  params: ValueSetterParams,
  dispatch: Dispatch<any>,
  t: Function,
  selectedPricingSheet: PricingSheet,
) => {
  try {
    const { data, colDef, oldValue: pOldValue, newValue: pNewValue } = params;
    const oldValue = pOldValue ? `${pOldValue}`.trim() : pOldValue;
    let newValue = typeof pNewValue === 'number' ? pNewValue.toString() : pNewValue;
    const columnId = colDef.colId || params.column.getColId();

    const valueChanged = arePriceValuesDifferent(oldValue, newValue);

    if (!columnId) {
      return false;
    }

    if (!data[columnId] && valueChanged) {
      const configurator = getConfiguratorFromClientId(clientDataTableId.split(':')[1]);
      if (configurator?.key !== SHEDVIEW) {
        openCantEditDialog(dispatch);
        return false;
      }

      newValue = getValidatedNewValue(newValue, oldValue);
      const rowsToAdd = [{ rowId: uuidV1(), width: columnId, length: data.priceY, [priceColumn]: newValue }];
      addDispatchCommandToUndo(
        dispatch,
        [removePricingBaseRows({ rows: rowsToAdd, selectedPricingSheet })],
        [addPricingBaseRow({ rows: rowsToAdd, selectedPricingSheet })],
        clientDataTableId,
        true,
      );
      return true;
    }

    if (valueChanged && colDef.editable) {
      // Data validation. Only allow prices that are valid numbers and greater than 0.
      newValue = getValidatedNewValue(newValue, oldValue);

      data[columnId][priceColumn] = newValue;
      const dataNew = { rowId: data[columnId].clientDataRowId, [priceColumn]: oldValue };

      if (!newValue && !localStorage.getItem(LocalStorage.HaveShownPricingBaseDeletingPriceDialog)) {
        dispatch(openNotificationDialog('', t(I18nKeys.PricingBaseDeletingPriceDialog)));
        dispatch(openDialog({ dialog: Dialogs.Notification }));
        localStorage.setItem(LocalStorage.HaveShownPricingBaseDeletingPriceDialog, '1');
      }

      const { newRows, oldRows } = processUpdatedValues(
        [{ data: dataNew, column: priceColumn, oldValue, newValue }],
        [],
      );
      if (newRows.length > 0) {
        addDispatchCommandToUndo(
          dispatch,
          [updatePricingSheetRows(oldRows)],
          [updatePricingSheetRows(newRows)],
          clientDataTableId,
          true,
        );
      }
    }
    return true;
  } catch (e) {
    console.error(`Failed to set value: `, e);
    return false;
  }
};

export const updateValues = (
  clientDataTableId: string,
  updates: { priceColumn: string; data: any; column: string; oldValue: any; newValue: any; colDef: ColDef }[],
  cellMetadata: CellMetadata[],
  dispatch: Dispatch<any>,
) => {
  try {
    const newValues: { table?: string; data: any; column: string; oldValue: any; newValue: any }[] = [];
    updates.forEach(({ priceColumn, data, colDef, column, oldValue: pOldValue, newValue: pNewValue }) => {
      const oldValue = pOldValue ? `${pOldValue}`.trim() : pOldValue;
      let newValue = typeof pNewValue === 'number' ? pNewValue.toString() : pNewValue;
      const columnId = colDef.colId || column;

      const valueChanged = arePriceValuesDifferent(oldValue, newValue);

      if (data[columnId] && valueChanged && colDef.editable) {
        newValue = getValidatedNewValue(newValue, oldValue);
        const dataNew = { rowId: data[columnId].clientDataRowId, [priceColumn]: oldValue };
        newValues.push({ data: dataNew, column: priceColumn, oldValue, newValue });
      }
    });

    const { newRows, oldRows } = processUpdatedValues(newValues, cellMetadata);
    if (newRows.length > 0) {
      addDispatchCommandToUndo(
        dispatch,
        [updatePricingSheetRows(oldRows)],
        [updatePricingSheetRows(newRows)],
        clientDataTableId,
        true,
      );
    }
    return true;
  } catch (e) {
    console.error(`Failed to set value: `, e);
    return false;
  }
};

export const deleteRows = (
  clientDataTableId: string,
  rowIds: string[],
  selectedPricingSheet: PricingSheet,
  dispatch: Dispatch<AnyAction>,
  t: Function,
): void => {
  const rowsToRemoveSet = new Set<PriceRow>();
  for (let i = 0; i < rowIds.length; i += 1) {
    const price = selectedPricingSheet?.prices?.find((r) => r.rowId === rowIds[i]);
    if (price) {
      rowsToRemoveSet.add(price);
    }
  }
  const rowsToRemove = Array.from(rowsToRemoveSet);

  if (rowsToRemove.length === selectedPricingSheet?.prices?.length) {
    dispatch(
      openNotificationDialog(
        t(I18nKeys.PricingBaseDeletingAllPricesDialogTitle),
        t(I18nKeys.PricingBaseDeletingAllPricesDialogMessage),
      ),
    );
    dispatch(openDialog({ dialog: Dialogs.Notification }));
    return;
  }

  if (rowsToRemove.length > 0) {
    addDispatchCommandToUndo(
      dispatch,
      [addPricingBaseRow({ rows: rowsToRemove, selectedPricingSheet })],
      [removePricingBaseRows({ rows: rowsToRemove, selectedPricingSheet })],
      clientDataTableId,
      true,
    );
  }
};

export const askToDeleteRows = (
  cellsFromSelectedRange: string[],
  pricingSheet: PricingSheet,
  clientDataTableId: string,
  dispatch: Dispatch<AnyAction>,
  t: Function,
  gridApi: GridApi,
) => {
  let basePrice;
  const cellsSelectedCount = cellsFromSelectedRange.length;
  if (cellsSelectedCount === 1) {
    basePrice = pricingSheet?.prices?.find((p) => p.rowId === cellsFromSelectedRange[0]);
  }

  const priceSheetTitle =
    pricingSheet?.priceSetLabel ||
    t(I18nKeys.PricingBaseAccordionPricingSheetTitle, { pricingSheetId: pricingSheet.id });

  dispatch(
    openConfirmationDialog(
      [],
      [
        () => {
          dispatch(closeDialog());
          clearSelections(gridApi);
          deleteRows(clientDataTableId, cellsFromSelectedRange, pricingSheet, dispatch, t);
        },
      ],
      undefined,
      t(I18nKeys.PricingBaseDeletePriceConfirmationDialogMessage, {
        prices: t(I18nKeys.PricingBaseDeletePriceConfirmationDialogMessageSizes, {
          size: basePrice
            ? t(I18nKeys.PricingBaseDeletePriceConfirmationDialogSize, {
                width: basePrice.width,
                length: basePrice.length,
              })
            : cellsSelectedCount,
          count: cellsSelectedCount,
        }),
        priceSheetTitle,
      }),
      t(I18nKeys.DialogDeleteButton),
      t(I18nKeys.DialogCancelButton),
      true,
    ),
  );
  dispatch(openDialog({ dialog: Dialogs.Confirmation }));
};

export const removePrices = (
  clientDataTableId: string,
  nodes: any[],
  dataColumn: string | undefined,
  dispatch: Dispatch<any>,
) => {
  try {
    const updatedRows = [];
    for (let i = 0; i < nodes.length; i += 1) {
      const node = nodes[i];
      if (node) {
        const priceColumn = dataColumn || node.priceColumn;
        const { data } = node;
        if (data) {
          const dataNew = { rowId: data.clientDataRowId, [priceColumn]: data[priceColumn] };
          updatedRows.push({
            data: dataNew,
            column: priceColumn,
            oldValue: data[priceColumn],
            newValue: '',
          });
        }
      }
    }
    const { newRows, oldRows } = processUpdatedValues(updatedRows, []);

    if (newRows.length > 0) {
      addDispatchCommandToUndo(
        dispatch,
        [updatePricingSheetRows(oldRows)],
        [updatePricingSheetRows(newRows)],
        clientDataTableId,
        true,
      );
    }
  } catch (e) {
    console.error(`Failed to remove prices: `, e);
  }
};

/**
 * AG Grid formatter for the pricing data. Handles the currency to display
 *
 * @param params
 * @param currency
 * @returns
 */
export const priceColumnFormatter = (
  params: ValueFormatterParams,
  priceColumn: PriceColumn,
  formatPriceWithDecimal: boolean,
  regionKey: string,
  currency?: string,
) => {
  const {
    colDef: { colId = '' },
    data,
  } = params;
  const { [colId]: value } = data;

  let price;
  let minimumFractionDigits = 0;
  if (colId) {
    price = value ? value[priceColumn] : '';
    if (formatPriceWithDecimal && price) {
      minimumFractionDigits = 2;
    }
  }

  const formattedPrice = price ? formatPrice(price, currency, minimumFractionDigits) : '';
  if (value?.hidden[regionKey]) return i18n.t(I18nKeys.PricingBaseHiddenPrice);
  return formattedPrice;
};

/**
 * AG Grid value getter for the pricing data
 *
 * @param params
 * @param cellMetadata
 * @returns
 */
export const pricingColumnValueGetter = (params: ValueGetterParams, priceColumn: PriceColumn) => {
  const { data, colDef } = params;
  const { colId = '' } = colDef;

  let price;
  if (data[colId]) {
    price = data[colId] ? data[colId][priceColumn] : '';
  }
  return price || '';
};

/**
 * Gets an array of client data row ids from the selected cell range
 *
 * @param gridApi
 * @returns
 */
export const getClientDataRowIdsFromSelectedRange = (gridApi: GridApi): string[] => {
  const cellRanges = gridApi.getCellRanges();
  if (!cellRanges || !cellRanges.length) return [];
  const { startRowIndex, endRowIndex, columns } = getCellRangeInfo(cellRanges);
  if (startRowIndex === undefined || endRowIndex === undefined) return [];

  const numRows = Math.abs(endRowIndex - startRowIndex) + 1;
  const clientDataRowIdsfromSelection = [] as any[];
  for (let i = 0; i < numRows; i += 1) {
    columns.forEach((column) => {
      const row = gridApi.getDisplayedRowAtIndex(i + startRowIndex)?.data[column]?.clientDataRowId;
      if (row) {
        clientDataRowIdsfromSelection.push(row);
      }
    });
  }
  return clientDataRowIdsfromSelection;
};

/**
 * Update the cell metadata for a cell
 *
 * @param clientDataTableId
 * @param updates
 * @param dispatch
 * @param getUndoActions - optional function to get additional undo actions
 */
export const updateCellMetadata = (
  clientDataTableId: string,
  updates: UpdateClientDataMetadata[],
  dispatch: Dispatch<any>,
) => {
  const [{ cellsMetadata = [] } = {}] = updates;

  const undoUpdates = updates.map((update) => ({
    ...update,
    value: getPropertyFromCellMetadata(cellsMetadata, update.rowId, update.colId, update.metadataProperty),
  }));

  addDispatchCommandToUndo(
    dispatch,
    [updatePricingMetadata(undoUpdates)],
    [updatePricingMetadata(updates)],
    clientDataTableId,
    true,
  );
};

/**
 * Processes the data from the grid to the clipboard
 *
 * @param clientDataTableId
 * @param params Process Data From Clipboard event params
 * @param param1 Cell metadata and dispatch function
 * @returns
 */
export const processDataFromClipboard = (
  params: ProcessDataFromClipboardParams,
  {
    clientDataTableId,
    gridViewType,
    regions,
    getPriceColumn,
    dispatch,
  }: {
    clientDataTableId: string;
    gridViewType: GridViewType;
    regions: Region[] | undefined;
    getPriceColumn: (colId: string) => PriceColumn;
    dispatch: Dispatch<any>;
  },
) => {
  const { t } = i18n;
  const {
    data: cellMatrix,
    api,
    columnApi,
    context: { regionKey: region },
  } = params;
  const { startRowIndex, columns: selectedColumns } = getCellRangeInfo(api.getCellRanges());

  // Columns must be ordered for correct index
  const allColumnsOrdered = (columnApi?.getAllGridColumns() || [])
    .map((col) => col.getColId())
    // Sort so that columns pinned left are first, then unpinned, then pinned right
    .sort((a, b) => {
      const [aOrder, bOrder] = [a, b].map((col) => {
        const column = columnApi?.getColumn(col);
        if (column?.isPinnedLeft()) return -1;
        if (column?.isPinnedRight()) return 1;
        return 0;
      });
      return aOrder - bOrder;
    });
  const selectedColumnsOrdered = allColumnsOrdered.filter((col) => selectedColumns.includes(col));
  const [firstColumn] = selectedColumnsOrdered;
  const columnStartIndex = allColumnsOrdered.indexOf(firstColumn);

  if (startRowIndex === undefined || columnStartIndex === -1) return null;

  const newRows: UpdateClientDataRow[] = [];
  const oldRows: UpdateClientDataRow[] = [];

  const clipboardDataMatrix = JSON.parse(
    localStorage.getItem(LocalStorage.ClientDataClipboardData) || '[]',
  ) as ClipboardData[][];

  let gridDataMatrix = cellMatrix;
  // After joining with delimiters, compare the grid data to the clipboard data
  const joinedGridData = gridDataMatrix.map((row) => row.join(COLUMN_DELIMITER)).join(ROW_DELIMITER);
  const joinedClipboardData = clipboardDataMatrix
    .map((row) => row.map((cell) => cell.value).join(COLUMN_DELIMITER))
    .join(ROW_DELIMITER);
  if (joinedGridData === joinedClipboardData) {
    // If the data is the same, use the clipboard data matrix to get rid of any extra rows/columns
    // from \t and \n characters
    if (
      gridDataMatrix.length !== clipboardDataMatrix.length ||
      gridDataMatrix.every((row, i) => row.length !== clipboardDataMatrix[i].length)
    ) {
      gridDataMatrix = clipboardDataMatrix.map((row) => row.map((cell) => cell.value));
    }
  }

  Array.from({ length: gridDataMatrix.length }).forEach((_el, i) => {
    // Find the row where the data will be pasted
    const { id: rowId, data } = api.getDisplayedRowAtIndex(startRowIndex + i) || {};

    // If the row doesn't exist, ignore it
    if (!rowId || data.rowIsReadOnly) return null;

    // Repeat the cell matrix if there are more rows than cells
    const [row] = [gridDataMatrix, clipboardDataMatrix].map((matrix) => matrix[i % matrix.length] || []);

    return (row as string[]).forEach((value, j) => {
      // If ran out columns, ignore this value
      if (columnStartIndex + j >= allColumnsOrdered.length) return;

      const column = allColumnsOrdered[columnStartIndex + j];
      const cellRegionKey =
        gridViewType === GridViewType.List
          ? regions?.find(({ priceColumn: col }: Region) => col === column)?.regionKey
          : region;

      // Don't add if there isn't a valid data entry for this column or if the value isinvalid
      if (!data[column] || value === undefined || value === null || data[column].hidden[cellRegionKey]) return;
      const priceColumn = getPriceColumn(column);

      const oldValue = data[column][priceColumn];
      const validatedValue = getValidatedNewValue(value, oldValue);

      oldRows.push({
        rowData: { [priceColumn]: data[column][priceColumn], rowId: data[column].clientDataRowId },
        column: priceColumn,
        value: data[column][priceColumn],
        formula: undefined,
      });
      newRows.push({
        rowData: { [priceColumn]: data[column][priceColumn], rowId: data[column].clientDataRowId },
        column: priceColumn,
        value: validatedValue,
        formula: undefined,
      });
    });
  });

  const completePaste = () =>
    addDispatchCommandToUndo(
      dispatch,
      [updatePricingSheetRows(oldRows)],
      [updatePricingSheetRows(newRows)],
      clientDataTableId,
      true,
    );

  const endRowIndex = (api?.getModel().getRowCount() || 0) - 1;
  if (
    gridDataMatrix.length > endRowIndex - startRowIndex + 1 ||
    gridDataMatrix[0].length > allColumnsOrdered.slice(columnStartIndex).length
  ) {
    dispatch(
      openConfirmationDialog(
        [],
        [completePaste],
        '',
        t(I18nKeys.PricingBaseClipboardExceedsSpaceWarning),
        t(I18nKeys.PasteAnywayButton),
      ),
    );
    dispatch(openDialog({ dialog: Dialogs.Confirmation }));
  } else {
    completePaste();
  }
  return null;
};

/**
 * Handles sheet name updates, updating each row in the selected sheet with the new name
 * The priceSetLabel column holds the sheet name value.
 *
 * @param title - the new title for this sheet
 * @param clientDataTableId - the table to update
 * @param selectedPricingSheet - the sheet that is being operated on
 * @param dispatch
 * @returns
 */
export const updateSheetTitle = (title: string, selectedPricingSheet: PricingSheet, dispatch: Dispatch<any>) => {
  const newRows: UpdateClientDataRow[] = [];

  const { prices = [] } = selectedPricingSheet;
  prices.forEach((price) => {
    newRows.push({
      rowData: { priceSetLabel: selectedPricingSheet.priceSetLabel, rowId: price.rowId },
      column: 'priceSetLabel',
      value: title,
      formula: undefined,
    });
  });

  dispatch(updatePricingSheetRows(newRows));
};

/**
 * Gets a column definition for the y-axis span header
 *
 * @param rowsToSpan number of rows to span
 * @returns
 */
export const getRowSpanHeader = (rowsToSpan: number): ColDef => ({
  ...defaultColumn,
  cellStyle: { fontWeight: 'bold' },
  pinned: 'left' as ColumnPinned,
  editable: false,
  headerName: '',
  headerComponent: SelectAllCellsHeader,
  colId: DefaultPricingColumnFieldNames.RowSpanLabel,
  field: DefaultPricingColumnFieldNames.RowSpanLabel,
  width: rowSpanHeaderWidth,
  suppressNavigable: true,
  headerTooltip: '',
  rowSpan: (params) => {
    const idx = params.node?.rowIndex || 1;
    const result = rowsToSpan - idx;
    return result;
  },
  cellClassRules: {
    'ag-grid-pricing-row-span-label': (params) => params?.value,
  },
});

/**
 * Gets a column definition for the y-axis label column
 *
 * @param y the y-axis dimension
 * @param gridViewType type of grid view type being displayed
 * @returns
 */
export const getYAxisDimensionColumnDef = (y: PricingSheetDimension, clientGridViewType: GridViewType): ColDef => ({
  ...defaultColumn,
  cellClassRules: { 'ag-grid-index-column': () => true },
  cellStyle: { fontWeight: 'bold', paddingRight: '6px', fontSize: '13px' },
  headerName: '',
  editable: false,
  headerTooltip: '',
  headerComponent: SelectAllCellsHeader,
  field: y,
  colId: y,
  initialWidth: clientGridViewType === GridViewType.Grid ? lengthColumnWidth : widthLengthColumnWidth,
});

/**
 * Autosizes the y-axis dimensioncolumn width
 *
 * @param columnApi AG Grid column api
 */
export const updateYAxisDimensionColumnWidth = (y: PricingSheetDimension, columnApi?: ColumnApi) => {
  columnApi?.getColumns()?.forEach((col) => {
    const colId = col.getColDef().field;
    const columns = [];
    if (colId === y) {
      columns.push(col);
    }
    if (columns.length > 0) {
      columnApi?.autoSizeColumns(columns);
    }
  });
};

/**
 * Determines whether the pricing sheet should be formatted with decimal places
 *
 * @param pricingSheet pricing sheet to check
 * @param regions all regions for the vendor
 * @param priceColumn the price column to check
 * @returns whether the pricing sheet should be formatted with decimal places
 */
export const formatPriceWithDecimal = (
  pricingSheet: PricingSheet | undefined,
  regions: Region[] = [],
  priceColumn: PriceColumn | undefined,
) => {
  const { prices = [] } = pricingSheet || {};
  return !!prices.find((price) => {
    if (regions) {
      for (let i = 0; i < regions.length; i += 1) {
        const region = regions[i];
        if (isDecimalPrice(price[region.priceColumn])) {
          return true;
        }
      }
    } else if (priceColumn) {
      if (isDecimalPrice(price[priceColumn])) {
        return true;
      }
    }
    return false;
  });
};

/**
 * Gets the width of the currency symbol text
 *
 * @param currency vendor currency
 * @returns
 */
const getCurrencyWidth = (currency: string) => {
  const currencySymbol = getCurrencySymbol(currency);
  return currencySymbol !== '$'
    ? getTextWidth(currencySymbol, 'calc(var(--ag-font-size) + 1px) var(--ag-font-family)')
    : 0;
};

/**
 *  Updates the price column widths to reflect the width of the currency text
 *
 * @param currency vendor currency
 * @param columnApi AG Grid column api
 * @param decimalFormat whether the price is in decimal format
 */
export const updatePriceColumnWidths = (currency: string, columnApi: ColumnApi | undefined, decimalFormat: boolean) => {
  const columnState = columnApi?.getColumnState();
  const currencyWidth = getCurrencyWidth(currency);

  columnState?.forEach((col) => {
    const { colId } = col;
    if (
      [DefaultPricingColumnFieldNames.RowSpanLabel, ...Object.values(PricingSheetDimension)].includes(
        colId as DefaultPricingColumnFieldNames,
      )
    ) {
      return;
    }
    if (decimalFormat) {
      columnApi?.setColumnWidth(colId, decimalPriceColumnWidth + currencyWidth);
    } else {
      columnApi?.setColumnWidth(colId, priceColumnWidth + currencyWidth);
    }
  });
};

/**
 * Gets column definitions for pricing sheet grids with the list view
 *
 * @param clientDataTableId the client data table id
 * @param regions all regions for the vendor
 * @param dispatch redux dispatch function
 * @param t i18n translation function
 * @param getCellClass function to get the cell class
 * @param useDecimalFormat whether to format the price with decimal places
 * @param groupingCellUpdates whether to group cell updates
 * @param availablePricePerRegion available prices per region
 * @param sumAvailablePrices total available prices
 * @param onGroupUpdateEdit function for group update edit
 * @param currency vendor currency
 * @param hasDefaultRegion whether the vendor has a default region
 * @returns column definitions for the pricing sheet grid
 */
export const getListGridViewColDefs = (
  clientDataTableId: string,
  selectedPricingSheet: PricingSheet,
  regions: Region[],
  dispatch: Dispatch<any>,
  t: Function,
  getCellClass: (cellClassParam: CellClassParams<PriceRow>) => string[],
  useDecimalFormat: boolean,
  groupingCellUpdates: boolean,
  availablePricePerRegion: Map<string, PricesPerRegionDiff>,
  onGroupUpdateEdit?: Function,
  currency?: string,
  hasDefaultRegion = false,
) => {
  const defs: ColDef[] = [];
  const onlyOneRegion = regions.length === 1;
  const { prices = [] } = selectedPricingSheet;

  regions.forEach((region) => {
    const { regionKey } = region;
    const isHiddenRegion = !!prices?.every(({ hidden }) => hidden[region.regionKey]);
    defs.push({
      headerName: !onlyOneRegion
        ? region.label
        : t(I18nKeys.PricingBaseDefaultRegionHeaderListViewLabel, { defaultValue: region.label }),
      field: region.priceColumn,
      colId: region.priceColumn,
      valueGetter: (params: ValueGetterParams) => pricingColumnValueGetter(params, region.priceColumn),
      valueFormatter: (params: ValueFormatterParams) =>
        priceColumnFormatter(params, region.priceColumn, useDecimalFormat, regionKey, currency),
      valueSetter: (params: ValueSetterParams) =>
        groupingCellUpdates && onGroupUpdateEdit
          ? onGroupUpdateEdit(params)
          : priceColumnValueSetter(clientDataTableId, region.priceColumn, params, dispatch, t, selectedPricingSheet),
      cellClass: getCellClass,
      headerTooltip: `${region.label}${
        hasDefaultRegion ? `\n${getSubtitleText(availablePricePerRegion, prices.length, region, isHiddenRegion)}` : ''
      }`,
      tooltipComponent: PricingGridTooltip,
      tooltipValueGetter: (params) => {
        const columnId = (params.column as Column).getColId();
        const { clientDataRowId } = params.data[columnId] || '';
        const { cellMetadata } = params.context;
        if (!hasCellMetadataProperty(cellMetadata, clientDataRowId, region.priceColumn, CellMetadataProperty.Note))
          return '';
        const note = getPropertyFromCellMetadata(
          cellMetadata,
          clientDataRowId,
          region.priceColumn,
          CellMetadataProperty.Note,
        );
        return note;
      },
      editable: ({ column, data }) => {
        const colId = column?.getColId();
        return !data[colId]?.hidden[regionKey];
      },
      suppressFillHandle: isHiddenRegion,
    });
  });
  return defs;
};

/**
 *  Gets column definitions for pricing sheet grids with the standard grid view
 *
 * @param clientDataTableId the client data table id
 * @param priceColumn the price column the grid is displaying
 * @param x the x-axis dimension
 * @param allDimensionValues all unique dimension values for the x-axis
 * @param dispatch redux dispatch function
 * @param t i18n translation function
 * @param getCellClass function to get the cell class
 * @param unit the unit of the x-axis dimension
 * @param useDecimalFormat whether to format the price with decimal places
 * @param groupingCellUpdates whether to group cell updates
 * @param onGroupUpdateEdit function for group update edit
 * @param currency vendor currency
 * @returns column definitions for the pricing sheet grid
 */
export const getXAxisDimensionColDefs = (
  clientDataTableId: string,
  selectedPricingSheet: PricingSheet,
  regions: Region[],
  priceColumn: PriceColumn,
  x: PricingSheetDimension,
  allDimensionValues: string[],
  dispatch: Dispatch<any>,
  t: Function,
  getCellClass: (cellClassParam: CellClassParams<PriceRow>) => string[],
  unit: string,
  useDecimalFormat: boolean,
  groupingCellUpdates: boolean,
  onGroupUpdateEdit?: Function,
  currency?: string,
) => {
  const defs: ColGroupDef[] = [];
  const children: ColDef[] = [];
  const regionKey = regions[0]?.regionKey;
  const { prices } = selectedPricingSheet;
  const isHiddenRegion = !!prices?.every(({ hidden }) => hidden[regionKey]);

  allDimensionValues.forEach((dimension) => {
    children.push({
      headerName: `${dimension} ${unit}`,
      colId: dimension,
      valueGetter: (params: ValueGetterParams) => pricingColumnValueGetter(params, priceColumn),
      valueFormatter: (params: ValueFormatterParams) =>
        priceColumnFormatter(params, priceColumn, useDecimalFormat, regionKey, currency),
      valueSetter: (params: ValueSetterParams) =>
        groupingCellUpdates && onGroupUpdateEdit
          ? onGroupUpdateEdit(params)
          : priceColumnValueSetter(clientDataTableId, priceColumn, params, dispatch, t, selectedPricingSheet),
      cellClass: getCellClass,
      tooltipComponent: ClientDataTooltip,
      tooltipValueGetter: (params) => {
        const columnId = (params.column as Column).getColId();
        const { clientDataRowId } = params.data[columnId] || '';
        const { cellMetadata } = params.context;
        if (!hasCellMetadataProperty(cellMetadata, clientDataRowId, priceColumn, CellMetadataProperty.Note)) return '';
        const note = getPropertyFromCellMetadata(cellMetadata, clientDataRowId, priceColumn, CellMetadataProperty.Note);
        return note;
      },
      editable: ({ column, data }) => {
        const colId = column?.getColId();
        return !data[colId]?.hidden[regionKey];
      },
      suppressFillHandle: isHiddenRegion,
    });
  });
  defs.push({
    headerName: getDimensionHeaderLabel(x, t),
    headerClass: ['ag-grid-x-dimension-header-column'],
    children,
  });
  return defs;
};

/**
 * Gets the table name to be updated for pricing sheet updates
 *
 * @param clientId the client id
 * @param selectedPricingTabId the selected pricing tab id
 * @param categoryKey the selected category key for size based pricing
 * @returns the table name to be updated
 */
export const getPricingSheetTable = (
  clientId: string,
  selectedPricingTabId: string | undefined,
  categoryKey: SizeBasedCategoryKey | undefined,
) => {
  let table;
  if (selectedPricingTabId === PricingTab.Base) {
    table = clientId.startsWith('shedview') ? 'basePrice' : 'pricingBase';
  }
  if (selectedPricingTabId === PricingTab.SizeBased) {
    switch (categoryKey) {
      case SizeBasedCategoryKey.LegHeight:
        table = 'pricingLegHeight';
        break;
      default:
        break;
    }
  }
  return table;
};

/**
 * Gets the grid x and y dimensions for a pricing sheet based on the pricing tab and grid view type.
 *
 * @param pricingTab the pricing tab currently being viewed
 * @param gridViewType the grid view type currently being used or selected
 * @returns the grid x and y dimensions for a pricing sheet
 */
export const getPricingSheetDimensions = (pricingTab: PricingTab, gridViewType: GridViewType) => {
  if (pricingTab === PricingTab.SizeBased) {
    return pricingSizeBasedGridView;
  }
  return gridViewType === GridViewType.Grid ? pricingBaseGridView : pricingBaseListView;
};

/**
 * Gets the different label parts for a pricing sheet that are generally displayed as bullet points.
 * If a priceSetLabel is available, that will be the only label part.
 *
 * @param pricingSheet the pricing sheet to get the label parts for
 * @param pricingTab the pricing tab currently being viewed
 * @param t i18n translation function
 * @returns the different label parts for a pricing sheet
 */
export const getPricingSheetLabelParts = (
  { priceSetLabel, attributes }: PricingSheet,
  pricingTab: string,
  t: TFunction,
) =>
  [
    priceSetLabel ||
      attributes.filter((attribute) => !attribute.hide).map((attribute) => getAttributeLabel(pricingTab, attribute, t)),
  ]
    .flat()
    .filter(Boolean)
    .sort((a, b) => (pricingTab !== PricingTab.SizeBased ? (a || '').localeCompare(b || '') : 0));

/**
 * Gets the default label for a pricing sheet based on the attributes when there is no priceSetLabel.
 *
 * @param attributes attributes for the pricing sheet
 * @returns the default label for the pricing sheet
 */
export const getPricingSheetDefaultLabel = (pricingSheet: PricingSheet | undefined, pricingTab: string) => {
  if (pricingTab === PricingTab.Base) return '';

  const { attributes = [] } = pricingSheet || {};
  return attributes
    .filter(
      ({ type, hide, label }) =>
        !hide && label && !([PricingSheetAttributeType.CustomExpression] as string[]).includes(type),
    )
    .map(({ label }) => label)
    .sort((a, b) => a.localeCompare(b))
    .join(', ');
};
